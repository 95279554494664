var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('v-container',{staticStyle:{"max-width":"1920px"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-carousel',{staticClass:"section-imaginary__items",staticStyle:{"width":"100%"},attrs:{"hide-delimiters":"","interval":2500,"height":"100%","cycle":""}},[_c('v-carousel-item',[_c('v-img',{attrs:{"src":(_vm.$assetsEndpoint + "/banner01.webp"),"height":"100%","min-height":"125px","max-height":"400px","contain":""}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":(_vm.$assetsEndpoint + "/banner02.webp"),"min-height":"125px","height":"100%","max-height":"400px","contain":""}})],1),_c('v-carousel-item',[_c('v-img',{attrs:{"src":(_vm.$assetsEndpoint + "/banner03.webp"),"min-height":"125px","height":"100%","max-height":"400px","contain":""}})],1)],1)],1)],1)],1),_c('v-container',{staticStyle:{"max-width":"500px"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"app-card"},[_c('v-card-text',[_c('span',{staticClass:"white--text"},[_vm._v(" ยอดเงินคงเหลือ ")]),_c('profile-connector',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var profile = ref.profile;
return [_c('h1',{staticClass:"app-primary--text"},[_vm._v(" ฿"+_vm._s(profile.balance.toFixed(2).toLocaleString())+" ")])]}}])})],1),_c('v-card-actions',{staticClass:"app-primary"},[_c('v-spacer'),_c('v-btn',{staticClass:"text-none",attrs:{"large":"","color":"app-secondary","depressed":"","href":_vm.generateGameLobbyUrl('', 'casino'),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                type: 'casino',
                gameId: '',
                imageUrl: (_vm.$assetsEndpoint + "/dking.webp")
              })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":_vm.$vuetify.breakpoint.xsOnly,"small":_vm.$vuetify.breakpoint.smAndUp,"color":"app-primary"}},[_vm._v(" fas fa-coins ")]),_vm._v(" Live คาสิโน ")],1),_c('v-btn',{staticClass:"text-none",attrs:{"large":"","color":"app-secondary","depressed":"","href":_vm.generateGameLobbyUrl('', 'bikini'),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                type: 'bikini',
                gameId: '',
                imageUrl: (_vm.$assetsEndpoint + "/bikini.webp")
              })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":_vm.$vuetify.breakpoint.xsOnly,"small":_vm.$vuetify.breakpoint.smAndUp,"color":"app-primary"}},[_vm._v(" $sexy ")]),_vm._v(" Bikini คาสิโน ")],1),_c('v-btn',{staticClass:"text-none",attrs:{"large":"","color":"app-secondary","depressed":"","href":_vm.generateGameLobbyUrl('26'),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                type: 'casino',
                gameId: '26',
                imageUrl: (_vm.$assetsEndpoint + "/pgslot.webp")
              })}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"x-small":_vm.$vuetify.breakpoint.xsOnly,"small":_vm.$vuetify.breakpoint.smAndUp,"color":"app-primary"}},[_vm._v(" fas fa-gamepad ")]),_vm._v(" สล็อต ")],1)],1)],1)],1),_c('profile-connector',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var profile = ref.profile;
return [(profile.recentGames.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"app-card"},[_c('div',{staticClass:"pa-1 app-primary"},[_vm._v(" เกมส์ล่าสุด ")]),_c('base-slider',{staticStyle:{"height":"100px","width":"auto"}},_vm._l((profile.recentGames),function(recentGame){return _c('a',{key:recentGame.gameId,attrs:{"href":_vm.generateLinkByRecentGame(recentGame.type, recentGame),"target":"_blank"}},[_c('img',{staticClass:"base-slider__item--image base-slider__item--auto-width",attrs:{"src":recentGame.imageUrl,"alt":"","width":"auto"}})])}),0)],1)],1):_vm._e()]}}])}),_vm._l((_vm.menus),function(menu,index){return _c('v-col',{key:("menu-" + index),attrs:{"md":"4","cols":"6"}},[_c('base-menu-item',{staticStyle:{"height":"100%"},attrs:{"icon":menu.icon,"title":menu.title,"to":{ name: menu.to }}})],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }